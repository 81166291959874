import React, { useEffect, useState } from "react";
import { navigate } from "@reach/router";
import { Form, Button } from "react-bootstrap"
import queryString from 'query-string';
import $ from 'jquery';

import {CustomLinks} from "../../common/utils";

const PeopleSearch = (props) => {
    let query_params = queryString.parse(props.location.search);
    var srch_text = query_params.q ? query_params.q : '';

    const[search_text, setSearchText] = useState(srch_text ? srch_text : '');

    const handleChange = (e) =>{
        setSearchText(e.target.value);
    }

    const handleSubmit = () =>{
        search_text ? navigate(`${props.location.pathname}?q=${search_text.toLowerCase().replace(/ /g, '-')}`) 
        : navigate(`${CustomLinks.our_people}${CustomLinks.find_team}`);

        $('html, body').animate({
            scrollTop: $(".our-teams").offset().top-140
        }, 1000);
    }

    const resetSrch = () => {
        setSearchText('');
        navigate(`${CustomLinks.our_people}${CustomLinks.find_team}`);
        $('html, body').animate({
            scrollTop: $(".our-teams").offset().top-140
        }, 1000);
    }

    useEffect(()=>{
        setSearchText(srch_text);
    },[srch_text])

    return (
        <React.Fragment>
            <Form action={`javascript:;`} onSubmit={handleSubmit} >
                <Form.Control type="text" name="q" placeholder="Search by person name, department, service and level" value={search_text} onChange={handleChange} autoComplete="off" />
                <span className="clear_btn" onClick={() => resetSrch()}>X</span>
                <Button type="submit" className="btn-submit" variant="link"><i className="icon-search-white"></i></Button>
            </Form>
        </React.Fragment>
    );
};
export default PeopleSearch;
