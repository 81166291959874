import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import dateFormat from "dateformat";
import Banner_CTA_Module from "../../../modules/Banner_CTA_Module";
import {CustomLinks, GetAuctions} from "../../common/utils";

const BannerContent = ({GQLPage, CTA_Module, openSearch, scrollToPos, setVideoId, setPlay, videoId, api_link}) => {

    const [items,setItemes] = useState([]);
    const [loading,setLoading] = useState(true);

    useEffect(async() =>{
        await GetAuctions(api_link).then(async res => {
            //console.log("res =>", res)
            if(res.status === 200){
                // if(props.auctionTag === "current-catalogue"){
                //     setItemes([res.data.auction])
                // } else{
                //     setItemes(res.data)
                // }
                setItemes(res.data);
                console.log("data---->", res.data);
                setLoading(false);
                
            } else{
                setItemes([]);
                setLoading(false);
            }
        }).catch(err =>
            console.log("err", err)
        );
    },[api_link])

    // let auctionFutureDate = items?.length > 0 ? ((items[0].auctionDate).toLocaleString('default', { month: 'long' }))+" "+((items[0].auctionDate).getUTCFullYear()) : "";
    console.log("auctionDate",items[0]?.auctionDate);
    var auction_date =  items?.length > 0 ? dateFormat(new Date(items[0]?.auctionDate), "dddd mmmm dS yyyy h:MM TT") : '';
    var fromdate = (new Date(items[0]?.auctionDate));
    //console.log("fromdate", fromdate);
    //console.log("date", items[0]?.auctionDate);
    var india_date = new Date();
    console.log("India Time:",india_date);
    var ukdate = india_date.toLocaleString('default', {
        timeZone: 'Europe/London',
        weekday:'short',
        month:'short',
        day:'2-digit',
        year:'numeric',
        hour:'numeric',
        minute:'numeric',
        second:'numeric'
    });
    var ukdate1 = ukdate.split(",");
    console.log("Uk Time:",ukdate1);
    var wkday = ukdate1[0];
    var fullday = ukdate1[1].split(" ");
    var ukday = fullday[1];
    var ukmonth = fullday[2].substring(0, fullday[2].length - 1)
    var ukyear = fullday[3];
    var uktime = ukdate1[2];
    var ukfulldaytime = wkday+" "+ukmonth+" "+ukday+" "+ukyear+" "+uktime;
    console.log("Convert Uk time:",ukfulldaytime);
    var today = new Date(ukfulldaytime).getTime();
    //var today = new Date();
    var countDownDate = new Date(fromdate).getTime();
    var displayTime = "";
    // Get today's date and time
    var now = new Date( ).getTime();
    //var now = ukfulldaytime;
    // Find the distance between now and the count down date
    var distance = countDownDate - now;
    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    // Output the result in an element with id="demo"
    console.log("Diff Time: days",days," hours",hours, " minutes",minutes);
    displayTime = "Next Auction: "+auction_date +  " | We're Live in  "  +days + " " + "Days " +  hours + " " + "Hours " + minutes + " " + "Min"
    //document.getElementById("demo").innerHTML = days + "d " + hours + "h "+ minutes + "m " + seconds + "s ";
    // If the count down is over, write some text
    if (distance < 0) {
        displayTime="";
    }

    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col>
                        <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                            <div className="banner-content">
                                <h1>{GQLPage.Banner_Heading}</h1>
                                <span className="sm-info">
                                    {GQLPage.Alias === "auctions" ? displayTime : GQLPage.Banner_Content}
                                </span>
                                <Banner_CTA_Module className="banner-search" setVideoId={setVideoId} setPlay={setPlay} GQLPage={GQLPage} CTA_Module={CTA_Module} openSearch={openSearch} scrollToPos={scrollToPos} />    
                            </div>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}
export default BannerContent
