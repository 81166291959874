import React, { useState } from "react";
// import { Link } from "gatsby"
// import { Container, Row, Col } from "react-bootstrap"
// import ScrollAnimation from 'react-animate-on-scroll';
// import {youtube_parser} from "../../common/utils";
import PlayVideo from '../../../components/Play/PlayVideo';
import Slider from '../Slider/Slider';
import BannerContent from './BannerContent';
// import {ImageModule} from "../../../modules/Image_Module";
import { useLocation } from "@reach/router";
import "./Banner.scss"

import $ from "jquery"
// Header component

const Banner = ({GQLPage, CTA_Module, openSearch, Slider_Module, api_link}) => {
    const [isPlay, setPlay] = useState(false);
    const [videoId, setVideoId] = useState('');

    const scrollToPos = (div_id) =>{
        var div_id_txt = div_id;

       
        if(div_id === "#contact" || div_id === "contact" || div_id === "#contact_office"){
            div_id_txt = "contact_office";
        }

        $('html, body').animate({
            scrollTop: $("#"+div_id_txt).offset().top - 200
        }, 'smooth');
    }

    let processedImages = JSON.stringify({});
    if (GQLPage?.imagetransforms?.Banner_Image_Transforms) {
        processedImages = GQLPage?.imagetransforms?.Banner_Image_Transforms;
    }
    const path = useLocation();
    const banner_img = GQLPage?.Banner_Image?.url;
    const img_src = path.pathname == '/' ? "" : banner_img;

    return (
        <React.Fragment>
            <header className={`banner ${Slider_Module && Slider_Module.length > 0 ? "no_pad" : ""}`} style={{backgroundImage:`url(${img_src})`}}>
                <div className="overlay_bg"></div>
                
                {Slider_Module && Slider_Module.length > 0 ? 
                    <Slider Slider_Module = {Slider_Module[0].Slider_Module} GQLPage={GQLPage} CTA_Module={CTA_Module} openSearch={openSearch} scrollToPos={scrollToPos}/>
                    :
                    <BannerContent setVideoId={setVideoId} setPlay={setPlay} GQLPage={GQLPage} CTA_Module={CTA_Module} openSearch={openSearch} scrollToPos={scrollToPos} api_link={api_link} />
                }
            </header>

            <PlayVideo
                isOpen={isPlay}
                isCloseFunction={setPlay}
                videoId={videoId}
                isAutoPlay={1}
            />
        </React.Fragment>
    )
}
export default Banner
