import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap"
import Slider from "react-slick";
import ScrollAnimation from 'react-animate-on-scroll';
import {ImageModule} from "../../../modules/Image_Module";
import Banner_CTA_Module from "../../../modules/Banner_CTA_Module";

const SliderBanner = ({GQLPage, CTA_Module, openSearch, Slider_Module, scrollToPos, setVideoId, setPlay,imagetransforms,id}) => {

    const settings = {
        dots: false,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        pauseOnHover:false,
        arrows: false,
        mobileFirst: true,
        autoplay:false,
        autoplaySpeed:5000,
        prevArrow:<span>&lt;</span>,
        nextArrow:<span>&rgt;</span>,
        dots: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: true,
                    arrows:false
                },
            },
        ],
    };
    let desktopScreen = true;
    if(typeof window !== "undefined"){
        desktopScreen = window.innerWidth > 740 ? true : false;
    }

    const [renderComponent, setRenderComponent] = useState(false);
    useEffect(() => {
      window.addEventListener("mousemove", () => {
        if (renderComponent === false) {
          setRenderComponent(true)
        }
      })
      window.addEventListener("touchmove", () => {
        if (renderComponent === false) {
          setRenderComponent(true)
        }
      })
      window.addEventListener("keypress", () => {
        if (renderComponent === false) {
          setRenderComponent(true)
        }
      })
   
    }, [])
    
    return (
        <React.Fragment>                             
                                
            {
                Slider_Module && Slider_Module.length > 0 && 
                <Slider {...settings} className="banner_slider">
                    { Slider_Module.map((item, index) => {
                        return(
                            <div className="slide_item " >
                                { renderComponent ? 
                                    <ImageModule ImageSrc={item.Slider_Image} altText={item.Silder_Heading + " - Strettons" } ggfx_results={GQLPage.ggfx_results} 
                                    strapi_id={GQLPage.id} loadLazy= {index === 0 ? false : true} imagename="articles.Slider_Module_Slider_Image.home_banner"
                                    /> :
                                    <div className="img_placeholder"> .</div>
                                }
                                <div className="slider_cta_btns">
                                    <Container>
                                        <Row>
                                            <Col>
                                                {/* <ScrollAnimation animateIn='fadeInUp' animateOnce={true}> */}
                                                <div className="banner-content">
                                                    { index == 0 ? 
                                                    <h1>{item.Silder_Heading}</h1> : <h2 className="h2-slider-heading">{item.Silder_Heading}</h2>}
                                                    <span className="sm-info">
                                                        {item.Slider_Content}
                                                    </span>

                                                    <Banner_CTA_Module className="banner-search" setVideoId={setVideoId} setPlay={setPlay} GQLPage={GQLPage} CTA_Module={CTA_Module} openSearch={openSearch} scrollToPos={scrollToPos} />
                                                </div>
                                                {/* </ScrollAnimation> */}
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                
                            </div>
                        )
                    })}
                </Slider>
            }
        </React.Fragment>
    )
}
export default SliderBanner
